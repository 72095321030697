<template>
  <section class="content">
    <div class="alert alert-info alert-dismissible fade show" role="alert" v-if="true">
      <strong class="mr-1"><i class="fas fa-1x fa-bullhorn"></i> Pengumuman!</strong>
          Proses system auto approve topup membutuhkan waktu 10 menit - 30 menit. Jika status belum diapprove, bisa info kami dan kiriman bukti transfer via live chat.
          <!-- Per Tgl 11 Desember 2021, Ketentuan Retur dengan Pemilihan kurir JNE ada update, detail klik disini <a href="https://app.boscod.com/info/ketentuan" target="_blank">Info Ketentuan</a> -->
      <button type="button" class="close" data-dismiss="alert" aria-label="Close">
              <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="flex justify-content-center">
      <div class="w-100 card">
        <div class="card-body">
          <div class="row">
            <div class="col-xl-3 col-md-3">
              <div class="card shadow-sm border rounded">
                <div class="card-body">
                  <div class="text-muted mb-1 font-13 text-uppercase flex">
                    Saldo Yang Tersedia
                  </div>
                  <h4 class="mb-1">
                    Rp<span> {{ allSaldo.current }} </span>
                  </h4>
                </div>
              </div>
            </div>
            <div class="col-xl-3 col-md-3">
              <div class="card shadow-sm border rounded">
                <div class="card-body">
                  <div class="text-muted mb-1 font-13 text-uppercase flex">
                    Dalam Proses Penarikan
                  </div>
                  <h4 class="mb-1">
                    Rp<span> {{ allSaldo.onWitdraw }} </span>
                  </h4>
                </div>
              </div>
            </div>
            <div class="col-xl-3 col-md-3">
              <div class="card shadow-sm border rounded">
                <div class="card-body">
                  <div class="text-muted mb-1 font-13 text-uppercase flex">
                    Saldo Pending
                  </div>
                  <h4 class="mb-1">
                    Rp<span> {{ allSaldo.pending }} </span>
                  </h4>
                </div>
              </div>
            </div>
            <div class="col-xl-3 col-md-3">
              <div
                class="card"
                style="background-color: rgba(245, 245, 245, 0.4)"
              >
                <button
                  type="button"
                  v-on:click="topupSaldo"
                  class="btn btn-primary btn-lg"
                >
                  Topup Saldo
                </button>
                <button
                  type="button"
                  v-on:click="withdrawSaldo"
                  class="btn btn-outline-success btn-lg mt-1"
                  :disabled="loadingWithdraw"
                >
                  Tarik Saldo  <span v-if="loadingWithdraw" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                </button>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-9 flex">
              <div class="row">
                <div class="col-auto">
                  <h4>Periode Laporan</h4>
                </div>

                <div class="col-md-3">
                  <div class="input-group mb-3">
                    <input
                      type="text"
                      ref="daterange"
                      class="form-control"
                      style="border-right: 0"
                    />
                    <div class="input-group-append">
                      <div
                        class="input-group-text"
                        style="background-color: #fff"
                      >
                        <span><span class="fa fa-calendar"></span></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-3 text-right">
              <div class="row">
                <div class="col-12">
                  <h4>.</h4>
                </div>
                <div class="col-12">
                  <div class="btn-group" ref="toolbar">
                    <button type="button" data-action="read" class="btn btn-default"><i
                      class="fa fa-sync-alt"></i></button>
                    <button type="button" :disabled="downloading" v-on:click="downloadXlsx"
                      data-action="export-xlsx" class="btn btn-default"><i class="fa fa-download"></i> <span class="d-none d-md-inline-block">Download</span></button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row" v-if="false">
            <div class="col-xl-3 col-md-3">
              <div class="card shadow-sm border rounded">
                <div class="card-body">
                  <div class="text-muted mb-1 font-13 text-uppercase flex">
                    Penarikan Selesai
                  </div>
                  <h4 class="mb-1">
                    Rp<span> {{ allSaldo.afterWithdraw }} </span>
                  </h4>
                </div>
              </div>
            </div>
            <div class="col-xl-3 col-md-3">
              <div class="card shadow-sm border rounded">
                <div class="card-body">
                  <div class="text-muted mb-1 font-13 text-uppercase flex">
                    Pendapatan COD
                  </div>
                  <h4 class="mb-1">
                    Rp<span> {{ allSaldo.cod }} </span>
                  </h4>
                </div>
              </div>
            </div>
            <div class="col-xl-3 col-md-3">
              <div class="card shadow-sm border rounded">
                <div class="card-body">
                  <div class="text-muted mb-1 font-13 text-uppercase flex">
                    Menghemat Ongkos Kirim
                  </div>
                  <h4 class="mb-1">
                    Rp<span> {{ allSaldo.ongkirDisc }} </span>
                  </h4>
                </div>
              </div>
            </div>
            <div class="col-xl-3 col-md-3">
              <div class="card shadow-sm border rounded">
                <div class="card-body">
                  <div class="text-muted mb-1 font-13 text-uppercase flex">
                    Ongkos Return Kirim
                  </div>
                  <h4 class="mb-1">Rp<span> 0 </span></h4>
                </div>
              </div>
            </div>
          </div>
          <div class="row" v-if="false">
            <div class="col-xl-3 col-md-3">
              <div class="card shadow-sm border rounded">
                <div class="card-body">
                  <div class="text-muted mb-1 font-13 text-uppercase flex">
                    Dikumpulkan Dari Pelanggan
                  </div>
                  <h4 class="mb-1">Rp<span> 0 </span></h4>
                </div>
              </div>
            </div>
            <div class="col-xl-3 col-md-3">
              <div class="card shadow-sm border rounded">
                <div class="card-body">
                  <div class="text-muted mb-1 font-13 text-uppercase flex">
                    Ongkos Kirim COD Yang Dibayar
                  </div>
                  <h4 class="mb-1">
                    Rp<span> {{ allSaldo.ongkirCodDibayar }} </span>
                  </h4>
                </div>
              </div>
            </div>
            <div class="col-xl-3 col-md-3">
              <div class="card shadow-sm border rounded">
                <div class="card-body">
                  <div class="text-muted mb-1 font-13 text-uppercase flex">
                    Pendapatan COD Bersih
                  </div>
                  <h4 class="mb-1">
                    Rp<span> {{ allSaldo.incomeCodBersih }} </span>
                  </h4>
                </div>
              </div>
            </div>
            <div class="col-xl-3 col-md-3">
              <div class="card shadow-sm border rounded">
                <div class="card-body">
                  <div class="text-muted mb-1 font-13 text-uppercase flex">
                    Ongkos Kirim Bersih Yang Dihemat
                  </div>
                  <h4 class="mb-1">Rp<span> 0 </span></h4>
                </div>
              </div>
            </div>
          </div>
          <div class="mb-3"></div>
          <div class="table-responsive">
            <table class="table table-hover" ref="tablesaldo" id="tblsaldo">
              <thead>
                <tr>
                  <th>TANGGAL</th>
                  <th>TIPE</th>
                  <th>DESKRIPSI</th>
                  <th>JUMLAH</th>
                  <th>KREDIT</th>
                  <th>SALDO</th>
                  <th>STATUS</th>
                  <th>INVOICE ID</th>
                </tr>
              </thead>
              <tbody @click="handleClick"></tbody>
            </table>
          </div>
        </div>
      </div>
      <!-- form saldo -->
      <div
        class="modal"
        tabindex="-1"
        role="dialog"
        ref="formSaldo"
        data-backdrop="static"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <form role="form" @submit.prevent="submitSaldo">
              <div class="modal-header">
                <h5 class="modal-title" id="title_saldo"></h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div class="alertx">
                  Gunakan Top-UP Saldo untuk melakukan pengiriman Non-COD.
                </div>
                <div class="row">
                  <div class="col-md-12 form-group">
                    <label class="control-label"></label>
                    <currency-input
                      class="form-control"
                      currency="IDR"
                      v-model="form.jumlah"
                      required="required"
                      placeholder="Jumlah"
                      v-bind:class="{ 'is-invalid': topupErr }"
                      onkeydown="return (event.ctrlKey || event.altKey 
                      || (47<event.keyCode && event.keyCode<58 && event.shiftKey==false)
                      || (95<event.keyCode && event.keyCode<106)|| (event.keyCode==8) || (event.keyCode==9) 
                      || (34<event.keyCode && event.keyCode<40) || (event.keyCode==32) || (event.keyCode==46))"
                    />
                    <span
                      class="invalid-feedback is-invalid" id="feedback-1" v-if="topupErr">
                      {{ topupMsg }}
                    </span>
                  </div>
                  <div class="col-md-12">
                    <div v-for="(bankData,key) in totalBank" class="d-flex flex-column">
                      <span class="text-muted mb-3 font-13 text-uppercase">{{key}}</span>
                      <div class="row">
                          <div class="col-6" v-for="bank in bankData" v-bind:key="bank.rekening_id" v-bind:value="bank.rekening_id" v-bind:class="{ 'is-invalid': topupErr }" @click="setPaymentMethod(bank.payment_category, bank.bank_id, bank.service_fee, bank.service_fee_type)">
                            <label class="mr-2 banklabel">
                              <input type="radio" name="rekening_id" class="card-input-element d-none" v-model="form.rekening_id" :value="bank.rekening_id">
                              <div class="card card-body bg-light d-flex flex-row justify-content-between align-items-center" style="gap:16px">
                                <div class="d-flex flex-column" style="align-items: center; gap: 8px;">
                                  <img :src="getBankLogo(bank.code)" width="60px;" />
                                  <span class="text-muted weight-400" style="font-size:10px" v-if="bank.service_fee">Fee {{bank.service_fee_type != 'percent' ? 'Rp'+bank.service_fee : bank.service_fee+'%'}}</span>
                                </div>
                                  {{getBankName(bank.code, bank.name)}}
                              </div>
                            </label>
                            <span
                              class="invalid-feedback is-invalid" id="feedback-1" v-if="topupErr">
                              {{ topupMsg }}
                            </span>
                          </div>
                          <!-- <div class="col-6" >
                          </div> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="submit"
                  class="btn btn-primary"
                  :disabled="loading">
                  Lanjutkan Pembayaran
                  <span v-if="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  </button>
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Tutup
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <!-- form withdraw -->
      <div
        class="modal"
        tabindex="-1"
        role="dialog"
        ref="formWithdraw"
        data-backdrop="static"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <form role="form" @submit.prevent="loadPin">
              <div class="modal-header">
                <h4 class="modal-title font-weight-bold" id="title_saldo">Tarik Saldo</h4>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div class="alert alert-warning mb-3" v-if="enableWithdraw===false">Mohon lengkapi data rekening terlebih dahulu. <a href="/settings/rekening" v-on:click="setupRekening">Atur Rekening</a></div>
                <div  v-else-if="enableWithdraw===true">
                  <div class="row pt-2">
                    <div class="col-sm-6">
                      <h5 class="font-weight-bold">Informasi Rekening</h5>
                    </div>
                    <div class="col-sm-6 text-right">
                      <button
                        type="button"
                        class="btn btn-link p-0"
                        v-on:click="setupRekening"
                      >
                        <u>Ubah Rekening</u>
                      </button>
                    </div>
                    <div class="col-12"><hr class="mt-0 mb-2" /></div>
                    <div class="col-sm-6">
                      <small class="text-grey">Atas Nama</small>
                      <h6 class="my-1">{{ infoRekening.bank_atasnama.toUpperCase() }}</h6>
                    </div>
                    <div class="col-sm-6">
                      <small class="text-grey">Nama Bank</small>
                      <h6 class="my-1">{{ infoRekening.name }}</h6>
                    </div>
                    <div class="col-sm-6">
                      <small class="text-grey">Nomor Rekening</small>
                      <h6 class="my-1">{{ infoRekening.bank_rekening }}</h6>
                    </div>
                    <div class="col-sm-12 mt-3">
                      <h5 class="font-weight-bold">Informasi Saldo</h5>
                    </div>
                    <div class="col-12"><hr class="mt-0 mb-2" /></div>
                    <div class="col-sm-6">
                      <small class="text-grey">Saldo Kamu Saat Ini</small>
                      <h6 class="my-1">{{ allSaldo.current }}</h6>
                    </div>
                    <div class="col-sm-6">
                      <small class="text-grey">Saldo Tertahan <span class="text-secondary" ref="btntoolTip" @mouseover="mouseOver" data-toggle="tooltip" data-placement="top" data-html="true" title="<h6>Dana yang ditahan senilai ongkir karena ada paket bermasalah & proses retur.</h6>"><i class="fa fa-info-circle"></i></span></small>
                      <h6 class="my-1">{{ allSaldo.retainedFundStr }}</h6>
                      <button
                        type="button"
                        class="btn btn-link p-0"
                        v-on:click="loadRetainedFund"
                      >
                        <u>Lihat daftar paket</u>
                      </button>
                    </div>
                    <div class="col-sm-12 mt-3">
                      <h5 class="font-weight-bold">Penarikan Saldo</h5>
                    </div>
                    <div class="col-12"><hr class="mt-0 mb-2" /></div>
                    <div class="col-md-12 form-group">
                      <h6 class="my-1">Nominal</h6>
                      <currency-input
                        class="form-control"
                        currency="IDR"
                        v-model="form.jumlah"
                        required="required"
                        placeholder="Jumlah"
                        :disabled="!enableWithdraw"
                        v-bind:class="{ 'is-invalid': withdrawErr }"
                        onkeydown="return (event.ctrlKey || event.altKey 
                        || (47<event.keyCode && event.keyCode<58 && event.shiftKey==false)
                        || (95<event.keyCode && event.keyCode<106)|| (event.keyCode==8) || (event.keyCode==9) 
                        || (34<event.keyCode && event.keyCode<40) || (event.keyCode==32) || (event.keyCode==46))"
                      />
                      <span
                        class="invalid-feedback is-invalid"
                        id="feedback-1"
                        v-if="withdrawErr"
                      >
                        {{ withdrawMsg }}
                      </span>
                      <div class="mt-3" v-if="enableWithdraw===true">
                          Catatan: Biaya admin untuk penarikan {{ infoRekening.name }} adalah <strong>{{ !infoRekening.admin_fee || infoRekening.admin_fee == 0 ? 'Gratis' : 'Rp ' + infoRekening.admin_fee }}</strong>
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <button type="button" class="btn btn-outline-primary btn-block" data-dismiss="modal">
                        Tutup
                      </button>
                    </div>
                    <div class="col-sm-6">
                      <button type="submit" :disabled="(!enableWithdraw || loading)" class="btn btn-primary btn-block" id="btn_saldo">
                        Tarik Saldo
                        <span v-if="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <!-- Preview after transaction -->
      <div
        class="modal"
        tabindex="-1"
        role="dialog"
        ref="formPreview"
        data-backdrop="static"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h4>Detail tagihan {{ saldo.invoice_code }}</h4>
            </div>
            <div class="modal-body">
              <div class="card bg-for-light">
                <span
                  style="font-size: 15px; font-weight: 700; text-align: left"
                  >Detail pembayaran</span
                >
                <table style="width: 100%; text-align: left">
                  <tr class="items">
                    <td style="width: 250px">Pembayaran</td>
                    <td style="text-align: right">
                      <div
                        style="display: flex; justify-content: space-between"
                      >
                        <div>IDR</div>
                        <div>{{ saldo.amount }}</div>
                      </div>
                    </td>
                  </tr>
                  <tr class="items" v-if="saldo.payment_method != 'winpay'">
                    <td style="width: 250px">Nomor unik</td>
                    <td style="text-align: right">
                      <div
                        style="display: flex; justify-content: space-between"
                      >
                        <div>IDR</div>
                        <div>{{ saldo.unique_code }}</div>
                      </div>
                    </td>
                  </tr>
                  <tr class="items" v-else>
                    <td style="width: 250px">Admin fee</td>
                    <td style="text-align: right">
                      <div
                        style="display: flex; justify-content: space-between"
                      >
                        <div>IDR</div>
                        <div>{{ saldo.admin_fee }}</div>
                      </div>
                    </td>
                  </tr>
                  <tr class="border-top">
                    <td style="width: 250px">Jumlah transfer</td>
                    <td style="text-align: right">
                      <div
                        style="display: flex; justify-content: space-between"
                      >
                        <div>IDR</div>
                        <div>{{ parseInt(saldo.debit) + parseInt(saldo.admin_fee ? saldo.admin_fee : 0) }}</div>
                      </div>
                    </td>
                  </tr>
                </table>
              </div>
              <div class="bank card bg-for-light padding">
                <h6>Transfer ke:</h6>
                <div class="flex" style="padding: 10px">
                  <div class="onebank">
                    <div class="d-flex content-center flex-column">
                      <img v-if="saldo.image_qr" :src="getBankLogo(saldo.image_qr)" width="60px;" style="margin:auto;" />
                      <img v-else-if="saldo.payment_method == 'winpay'" :src="getBankLogo(saldo.payment_method_code)" width="60px;" style="margin:auto;" />
                      <img v-else :src="getBankLogo(saldo.code)" width="60px;" style="margin:auto;" />
                    </div>
                    <div class="bankdetails">
                      <h6 class="bankname">{{saldo.payment_method != 'winpay' ? saldo.bank : saldo.payment_method_winpay}}</h6>
                      <h6 class="number">{{saldo.payment_method != 'winpay' ? saldo.rekening : saldo.payment_code}}</h6>
                      <h6>{{saldo.atas_nama}}</h6>
                    </div>
                  </div>
                </div>
                <h6>Total yang ditransfer:</h6>
                <h4 class="amount">Rp {{ parseInt(saldo.debit) + parseInt(saldo.admin_fee ? saldo.admin_fee : 0) }}</h4>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-primary btn-block"
                data-dismiss="modal">
                Selesai
              </button>
            </div>
            <div class="mb-2"></div>
          </div>
        </div>
      </div>
    </div>
    <Pin :showPin="showPin" :loadingPin="loadingPin" :messagePin="messagePin" v-on:change="submitWithdraw" />
  </section>
</template>
<script>
import { authFetch, auth, createTable, formatCurrency } from "@/libs/hxcore";
import Swal from "sweetalert2";
import $ from "jquery";
import { CurrencyInput } from "vue-currency-input";
import Daterangepicker from "daterangepicker";
import moment from "moment";
import "daterangepicker/daterangepicker.css";
import Pin from "@/dialog/Pin";
export default {
  name: "Saldo",
  data() {
    return {
      dateStart: "",
      dateEnd: "",
      errors: [],
      totalBank: [],
      method: "",
      form: {
        jumlah: "",
        rekening_id: "",
        admin_fee: 0,
        admin_fee_type:'',
        payment_category: '',
        payment_channel:''
      },
      saldo: {},
      currentSaldo: 0,
      total: "",
      roles: "",
      role_id: "",
      showPin: false,
      loadingPin: false,
      messagePin: 'ubah rekening',
      withdrawErr: false,
      withdrawMsg: "",
      topupErr: false,
      topupMsg: "",
      withdrawMsg: "",
      allSaldo: {},
      infoRekening: null,
      loadingWithdraw: false,
      suspect:false,
      enableWithdraw: null,
      loading: false,
      downloading: false,
      filter: {},
      pinCheck: false,
      max_topup_trx: 0,
      max_topup_daily: 0,
      max_withdraw_trx: 0,
      max_withdraw_daily: 0
    };
  },
  created: function () {
    this.roles = this.$route.meta.roles;
    console.log("load initial data", this.$route);
    this.dateStart = moment().add(-1, 'M').startOf("month").format("YYYY-MM-DD");
    this.dateEnd = moment().format("YYYY-MM-DD");
    if (this.$route.params.filter)
      this.filter = this.$route.params.filter;
    if (this.filter.d1 && this.filter.d2) {
      this.d1 = moment(this.filter.d1);
      this.d2 = moment(this.filter.d2);
    }
    if (this.dateStart && this.dateEnd) {
      this.d1 = moment(this.dateStart);
      this.d2 = moment(this.dateEnd);
    }
    if (Object.keys(this.filter).length < 1) {
      if (sessionStorage.filterData) this.filter = JSON.parse(sessionStorage.filterData);
    } else {
      sessionStorage.filterData = JSON.stringify(this.filter);
      sessionStorage.filterStatus = 1;
    }
    this.filter.datatype = this.data_type;
    this.filter.d1 = !this.filter.d1 ? moment().add(-1, 'M').startOf("month").format("YYYY-MM-DD") : this.filter.d1;
    this.filter.d2 = !this.filter.d2 ? moment().format("YYYY-MM-DD") : this.filter.d2;
    this.loadSaldo();
  },
  methods: {
  	mouseOver: function(e){
  		let ev = this.$refs;
  		$(ev.btntoolTip).tooltip('show');
      // $(ev.btntoolTip).css("cursor", "help");
    },
    mouseOver2: function(e){
      let ev = this.$refs;
      $(ev.btntoolTip2).tooltip('show');
      // $(ev.btntoolTip).css("cursor", "help");
    },
    getBankLogo(code) {
      if (!code) {
        return '';
      } else if (code.includes('http')) {
        return code
      } else {
        const fileName = code.toLowerCase();
        return require(`../assets/img/bank/${fileName}-light.png`) // the module request
      }
    },
    getBankName(code, name) {
      if (code.includes('http')) {
        return name
      } else {
        return code
      }
    },
    setupRekening: function(ev){
      ev.preventDefault()
      const e = this.$refs;
      $(e.formWithdraw).modal("hide");
      this.$router.push({
					path: "/settings/rekening"
        });
      return false;
    },
    loadRetainedFund: function(ev){
      ev.preventDefault()
      const e = this.$refs;
      $(e.formWithdraw).modal("hide");
      this.$router.push({
					path: "/saldo/withdraw/retained"
        });
      return false;
    },
    loadRekening: function () {
      authFetch("/payment/saldo/rekening")
        .then((res) => {
            if (res.status === 201) {} else if (res.status === 400) {}
            return res.json();
        })
        .then((js) => {
            this.infoRekening = js.data;
            console.log('tes',js.data);
            if(js.data){
              this.enableWithdraw = true;
              if(js.data.marking == 'suspect' || js.data.marking == 'suspect rekening')
                this.suspect = true;
              }
            else this.enableWithdraw = false;
        });
    },
    withdrawSaldo: function () {
      this.pinCheck = true;
      this.loadingWithdraw = true;
      let tipe = 'topup';

      authFetch("/payment/saldo/bank?pin="+ this.pinCheck +"&tipe="+tipe)
      .then((res) => {
          if (res.status === 201) {} else if (res.status === 400) {}
          return res.json();
      })
      .then((js) => {
        if (!js.status) {
          Swal.fire({
            title: js.msg, 
            icon: 'warning', 
            confirmButtonColor: '#FF7203',
            showCloseButton: true,
          }).then((result) => {
            if (result.isConfirmed) {
              if (js.tipe == 'pin') {
                this.$router.push({ path: "/settings/pin"});
              } else if (js.tipe == 'ktp') {
                this.$router.push({ path: "/verification"});
              }
            }
          });
        } else {
          this.showWithdraw();
        }
        this.loadingWithdraw = false;
      });
      authFetch("/payment/saldo/max_withdraw")
        .then((res) => {
            if (res.status === 201) {} else if (res.status === 400) {}
            return res.json();
        })
        .then((js) => {
            this.max_withdraw_trx = parseInt(js.data.max_withdraw_trx);
            this.max_withdraw_daily = parseInt(js.data.max_withdraw_daily);
        })
    },
    loadSaldo: function () {
      var filter='';
      filter+='d1='+this.dateStart;
      filter+='&d2='+this.dateEnd;
      console.log(filter)
      authFetch("/payment/saldo/allsaldo?"+filter).then((res) => {
        res.json().then((json) => {
          this.currentSaldo = json.data.current;
          this.allSaldo = json.data;
          this.allSaldo.current = formatCurrency(this.allSaldo.current);
          this.allSaldo.onWitdraw = formatCurrency(this.allSaldo.onWitdraw);
          this.allSaldo.pending = formatCurrency(this.allSaldo.pending);
          this.allSaldo.afterWithdraw = formatCurrency(
            this.allSaldo.afterWithdraw
          );
          this.allSaldo.cod = formatCurrency(this.allSaldo.cod);
          this.allSaldo.ongkirDisc = formatCurrency(this.allSaldo.ongkirDisc);
          this.allSaldo.ongkirCodDibayar = formatCurrency(
            this.allSaldo.ongkirCodDibayar
          );
          this.allSaldo.incomeCodBersih = formatCurrency(
            this.allSaldo.incomeCodBersih
          );
          this.allSaldo.retainedFundStr = this.allSaldo.retainedFund==0?'-' : formatCurrency(
            this.allSaldo.retainedFund
          );
        });
      });
    },
    onPeriode() {
      this.tbl.api().ajax.reload();
    },
    topupSaldo: function () {
        const e = this.$refs;
        this.topupErr = false;
        this.topupMsg = "";

        authFetch("/payment/saldo/max_topup")
        .then((res) => {
            if (res.status === 201) {} else if (res.status === 400) {}
            return res.json();
        })
        .then((js) => {
            this.max_topup_trx = parseInt(js.data.max_topup_trx);
            this.max_topup_daily = parseInt(js.data.max_topup_daily);
        })
        
        let tipe = 'topup';
        authFetch("/payment/saldo/bank?pin="+ this.pinCheck +"&tipe="+tipe)
        .then((res) => {
            if (res.status === 201) {} else if (res.status === 400) {}
            return res.json();
        })
        .then((js) => {
            this.totalBank = js.data;
            $("#title_saldo").html("Tambah Saldo");
            $("#btn_saldo").html("LANJUTKAN PEMBAYARAN");
            $(e.formSaldo).modal("show");
        });
    },
    showWithdraw: function () {
      authFetch("/shipper/isset_pin")
      .then((res) => {
        if (res.status === 201) {
        } else if (res.status === 400) {
        }
        return res.json();
      })
      .then((js) => {
        if (!js.success) {
          this.showPin = !this.showPin;
        } else {
          const e = this.$refs;
          this.withdrawErr = false;
          this.withdrawMsg = "";
          $(e.formWithdraw).modal("show");
        }
      });
    },
    toPrice(price, unique) {
      let saldo = parseInt(price) + parseInt(unique);
      // let reverse = saldo.toString().split("").reverse().join(""),
      //   ribuan = reverse.match(/\d{1,3}/g);
      // ribuan = ribuan.join(".").split("").reverse().join("");
      return formatCurrency(saldo);
    },
    submitSaldo: function (ev) {
      this.$gtag.event(`click`, {
        'event_name': 'Top-up'
      });
      console.log("ev", ev);
      if (!this.form.rekening_id) {
        this.topupErr = true;
        this.topupMsg = "Rekening belum dipilih";
        return false;
      }
      if (this.form.jumlah <= 0) {
        this.topupErr = true;
        this.topupMsg = "Jumlah Top-UP harus lebih dari 0";
        return false;
      }
      if (this.form.jumlah < 15000) {
        this.topupErr = true;
        this.topupMsg = "Jumlah Top-UP minimal Rp. 15.000";
        return false;
      }
      if (this.form.jumlah > this.max_topup_trx) {
        this.topupErr = true;
        this.topupMsg = "Maaf, batas maksimal top up bosCOD adalah Rp "+this.formatRupiah(this.max_topup_trx);
        return false;
      }
      if(!this.form.jumlah || !this.form.rekening_id)
        return false;
      const e = this.$refs;
      var data = Object.keys(this.form)
        .map(
          (key) =>
            encodeURIComponent(key) + "=" + encodeURIComponent(this.form[key])
        )
        .join("&");
      if (this.form.admin_fee_type == 'percent') {
        this.form.admin_fee = Math.ceil(parseInt(this.form.jumlah) * (parseFloat(this.form.admin_fee)/100))
      }
      var data = {
        jumlah: this.form.jumlah,
        rekening_id: this.form.rekening_id,
        admin_fee: this.form.admin_fee,
        payment_category: this.form.payment_category,
        payment_channel: this.form.payment_channel
      }
      // var setmethod = this.form.id ? "PUT" : "POST";
      var urlSubmit = "/payment/saldo";
      // if (this.method == "PUT")
      //   urlSubmit = "/order/address/" + this.form.id;

      this.loading = true;
      authFetch(urlSubmit, {
        method: "POST",
        body: JSON.stringify(data),
      })
        .then((res) => {
          this.loading = true;
          if (res.status === 201) {
          } else if (res.status === 400) {
          }
          return res.json();
        })
        .then((js) => {
          this.errors = [];
          this.loading = false;
          if (js.success) {
            this.saldo = js.data;
            let price = this.toPrice(js.data.debit, js.data.unique_code);
            this.total = price;
            this.loadSaldo();
            $(e.formPreview).modal("show");
          } else {
            Swal.fire(
              js.error_type ? "Perhatian<br>Limit Top Up Harian bosCOD Rp "+ this.formatRupiah(this.max_topup_daily) : '',
              js.desc ? js.desc : "Proses pengajuan top up gagal",
              "error"
            );
          }
          this.tbl.api().ajax.reload();
          this.form = {};
          $(e.formSaldo).modal("hide");
        });
      ev.preventDefault();
    },
    loadPin: function (ev) {
      if (this.form.jumlah > this.max_withdraw_trx) {
        this.withdrawErr = true;
        this.withdrawMsg = "Maaf, batas maksimal withdraw bosCOD adalah Rp "+this.formatRupiah(this.max_withdraw_trx);
        return false;
      }
      if (this.form.jumlah > this.currentSaldo) {
        this.withdrawErr = true;
        this.withdrawMsg = "Jumlah penarikan melebihi saldo yang tersedia";
        return false;
      } else if (this.form.jumlah > (this.currentSaldo-this.allSaldo.retainedFund)) {
        this.withdrawErr = true;
        this.withdrawMsg = "Jumlah penarikan tidak mencukupi";
        return false;
      } else if ((this.infoRekening.bank_id == 94 && this.form.jumlah < 20000) || (this.infoRekening.bank_id != 94 && this.form.jumlah < 15000)) {
        this.withdrawErr = true;
        this.withdrawMsg = "Jumlah penarikan minimal Rp. " + (this.infoRekening.bank_id == 94 ? "20.000" : "15.000");
        return false;
      } else {
        this.withdrawErr = false;
        this.withdrawMsg = "";
      }
      this.showPin = !this.showPin;
      ev.preventDefault();
    },
    submitWithdraw: function (pinSet) {
      this.loadingPin = true;
      this.$gtag.event(`click`, {
        'event_name': 'Tarik'
      });
      const e = this.$refs;
      this.form.admin_fee = !this.infoRekening.admin_fee || this.infoRekening.admin_fee == 0 ? 0 : this.infoRekening.admin_fee;

      var data = Object.keys(this.form)
        .map(
          (key) =>
            encodeURIComponent(key) + "=" + encodeURIComponent(this.form[key])
        )
        .join("&");
      data = data + '&pin_no=' + pinSet;
      // var setmethod = this.form.id ? "PUT" : "POST";
      var urlSubmit = "/payment/saldo/withdraw";
      // if (this.method == "PUT")
      //   urlSubmit = "/order/address/" + this.form.id;
      this.loading = true;
      authFetch(urlSubmit, {
        method: "POST",
        body: data,
      })
        .then((res) => {
          this.loading = true;
          if (res.status === 201) {
          } else if (res.status === 400) {
          }
          return res.json();
        })
        .then((js) => {
          this.loadingPin = false;
          this.loading = false;
          this.withdrawErr = false;
          this.withdrawMsg = "";
          this.errors = [];
          if (js.success) {
            Swal.fire(
              "Pemberitahuan",
              "Proses pengajuan penarikan dana berhasil, silakan tunggu proses dari admin",
              "success"
            );
            this.loadSaldo();
            $(e.formWithdraw).modal("hide");
            this.tbl.api().ajax.reload();
            this.form = {};
          } else {
            Swal.fire(
              "Pemberitahuan",
              js.desc ? js.desc : "Proses pengajuan penarikan dana gagal",
              "error"
            );
          }
        });
    },
    handleClick(e) {
      if (e.target.matches(".link-role")) {
        authFetch("/payment/saldo/" + e.target.dataset.id).then((res) => {
          res.json().then((json) => {
            this.saldo = json;
            let price = this.toPrice(json.debit, json.unique_code);
            this.total = price;
            $(this.$refs.formPreview).modal("show");
          });
        });
        return false;
      }
      // if (e.target.matches("button")) {
      //   console.log(e.target);
      //   return false;
      // }
    },
    downloadXlsx: function (e) {
      this.$gtag.event(`click`, {
        'event_name': 'Download Saldo'
      });
      this.userInfo = auth.user();
      this.downloading = true;
      var table = $('#tblsaldo').DataTable();
      var length = table.page.info().recordsTotal;
      
      if (length > 20000) {
        Swal.fire("Proses gagal", `Maksimal data sebanyak kurang lebih 20.000 data.`, "error");
        this.downloading = false;
        return false;
      }
      
      var filter = JSON.parse(JSON.stringify(this.filter));
          filter.q = $("input[name=q]").val();
          filter.category = $("select[name=category]").val();

      console.log('FEELTER', filter);
      var data = Object.keys(filter)
        .map(
          (key) =>
          encodeURIComponent(key) + "=" + (filter[key] === false ? 0 : encodeURIComponent(filter[key]))
        )
        .join("&");
      console.log('FILTER', data);
      this.loadingContent = true;
      authFetch("/report/saldo/excell/" + this.userInfo.id, {
        method: "POST",
        body: data,
      })
      .then((response) => {
        if (response.status === 201) {
        } else if (response.status === 400) {
        } else if (response.status === 500){
          Swal.fire("Timeout!", ``, "error");
          this.loading2 = false;
          this.msg = '';
          return false;
        }

        return response.blob();
      })
      .then((blob) => {
        setTimeout(() => {
          this.downloading = false;
        }, 1000);
        this.loadingContent = false;
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement("a");
        a.href = url;
        a.download = "data.xlsx";
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove(); //afterwards we remove the element again
      });
    },
    setPaymentMethod(cat, code, fee, feeType) {
      this.form.payment_category = cat 
      this.form.payment_channel = code
      this.form.admin_fee_type = feeType
      this.form.admin_fee = fee
    }
  },
  mounted() {
    const e = this.$refs;
    const self = this;
    new Daterangepicker(this.$refs.daterange, {
      startDate: moment().add(-1, 'M').startOf("month"),
      endDate: moment(),
      locale: {
          format: 'DD/MM/YYYY'
      }
    }, function (d1, d2) {
      self.dateStart = d1.format("YYYY-MM-DD");
      self.dateEnd = d2.format("YYYY-MM-DD");
      self.filter.d1 = d1.format("YYYY-MM-DD");
      self.filter.d2 = d2.format("YYYY-MM-DD");
      self.onPeriode();
      self.loadSaldo();
    });
    $(e.formWithdraw).on("show.bs.modal", (e) => {
        if(this.infoRekening==null)
        {
            console.log('load info rekening');
            this.loadRekening();
        }
    });
    this.tbl = createTable(e.tablesaldo, {
      title: "",
      roles: this.$route.params.roles,
      selectedRowClass: "",
      serverSide: true,
      frame: false,
      processing: true,
      language: {
        processing: '<i class="fa fa-spinner fa-5x fa-spin" style="font-size:50px;color:black;"></i>'},
      displayLength: 10,
      lengthChange: true,
      sort:false,
      ajax: "/payment/saldo",
      paramData: function (d) {
        d.d1 = self.dateStart;
        d.d2 = self.dateEnd;
      },
      columns: [
        { data: "deposit_date" },
        { data: "trans_type" },
        { data: "note" },
        { 
          data: "debit", 
          class:'text-right',
          render: function(data, type, row, meta) {
            if((!data || data==0) && row.credit > 0)data = -row.credit;
            return formatCurrency(data);
          }
        },
        { 
          data: "credit", 
          visible:false,
          class:'text-right',
          render: function(data, type, row, meta) {
            return formatCurrency(data);
          }
        },
        { data: "saldo" ,
          class:'text-right',
          render: function(data, type, row, meta) {
            return formatCurrency(data);
          }
          },
        { data: "status", render: function (data, type, row, meta) {
            let status = '';
            if(row.active < 1)
            {
                return `<span class="badge badge-danger">DITOLAK</span>`;
            } else {
                if (row.status == 0) {
                    status = `<span class="badge badge-warning">PENDING</span>`;
                } else if (row.status == 1) {
					status = `<span class="badge badge-success">SUKSES</span>`;
                } else {
                    status = `<span class="badge badge-info">PROSES</span>`;
				}
            }
            return status;
        } },
        { data: "invoice_code" },
      ],
      filterBy: [2, 7],
      rowCallback: function (row, data) {
        
        if (data.trans_type == 0) {
          if(data.status == 0)$("td:eq(6)", row).html(
            '<a class="link link-role" data-id="' +
              data.id +
              '" style="cursor:pointer;">' +
              data.invoice_code +
              "</a>"
          );
          $("td:eq(1)", row).html(
            `<span class="badge badge-info">TOP UP</span>`
          );
        } else if (data.trans_type == 1) {
          if(data.debit > 0)
          $("td:eq(1)", row).html(
            `<span class="badge badge-info">REKONSILIASI</span>`
          );

          else $("td:eq(1)", row).html(
            `<span class="badge badge-danger">REKONSILIASI</span>`
          );
        } else if (data.trans_type == 11) {
          $("td:eq(1)", row).html(
            `<span class="badge badge-danger">WITHDRAW</span>`
          );
          //$("td:eq(3)", row).html(data.debit ? data.debit : -data.credit);
        } else {
          $("td:eq(1)", row).html("");
        }
      },
      initComplete: function () {
				$('.loading-overlay').removeClass('show');
			},
    });
  },
  components: {
    Pin,
    CurrencyInput
  },
};
</script>
<style scoped>
.bank {
  position: relative;
  display: block;
  padding: 10px;
  margin: 0 10px 10px;
  text-align: center;
}
.onebank {
  display: inline-block;
  text-align: center;
  width: 50%;
}

.onebank img {
  width: 130px;
  margin: 10px;
  padding-top: 10px;
}

.bankdetails {
  padding: 10px;
}

.bankname {
  font-weight: 600;
  font-size: 15px;
}

.amount,
.bankdetails .number {
  color: #2e47ba;
}

.banklabel {
  width: 100%;
  font-size: 1rem;
}

.card-input-element+.card {
  height: calc(36px + 2*1rem);
  color: #E05119;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 2px solid transparent;
  border-radius: 4px;
}

.card-input-element+.card:hover {
  cursor: pointer;
}

.card-input-element:checked+.card {
  border: 2px solid #E05119;
  -webkit-transition: border .3s;
  -o-transition: border .3s;
  transition: border .3s;
}

.card-input-element:checked+.card::after {
  content: '\2713';
  color: #E05119;
  font-size: 24px;
  -webkit-animation-name: fadeInCheckbox;
  animation-name: fadeInCheckbox;
  -webkit-animation-duration: .5s;
  animation-duration: .5s;
  -webkit-animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.description-text{
  font-size: 15px;
}

.btn-circle {
    width: 15px;
    height: 15px;
    padding: 0px 0px;
    border-radius: 10px;
    text-align: center;
    font-size: 10px;
    line-height: 1.42857;
}

.center {
  /* position: absolute; */
  top: 50%;
  padding: 70px 0;
}

@-webkit-keyframes fadeInCheckbox {
  from {
    opacity: 0;
    -webkit-transform: rotateZ(-20deg);
  }
  to {
    opacity: 1;
    -webkit-transform: rotateZ(0deg);
  }
}

@keyframes fadeInCheckbox {
  from {
    opacity: 0;
    transform: rotateZ(-20deg);
  }
  to {
    opacity: 1;
    transform: rotateZ(0deg);
  }
}

</style>